
import './App.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './media.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import Dashboard from './JS/dashboard';
import Gussing from './JS//gussing';
import Video from './JS/video';
import Results from './JS/result';
import Games from './JS/games';
import Advertisements from './JS/advertisements';
import Vendor from './JS/vendor';
import ChangePassword from './JS/changePassword.js'
import Login from "./JS/login";
import Footer from './JS/footer.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash, faTachometerAlt, faQuestionCircle, faVideo, faUser, faChartLine, faGamepad, faBullhorn, faStore, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AllVisitorNewVisitor from './JS/visitors.js';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingSpiner } from './JS/utilities/loadingSpinner.js';

import bgmLogo from './images/bgm_logo.png'
import 'react-toastify/dist/ReactToastify.css';



library.add(faTrash, faTachometerAlt, faQuestionCircle, faVideo, faUser, faChartLine, faGamepad, faBullhorn, faStore, faTable);
function App({ gameData, gussing, advetise, video, result, newVisitor, allVisitor }) {

  // const navigate = useNavigate();
  // Initialize state to hold window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true); // loading state

  // Update state when the window is resized
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define the width threshold
  const widthThreshold = 992;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  // let nevigate = useNavigate()
  useEffect(() => {
    setUserData(sessionStorage.mobile);
    // console.log('sessionStorage.mobile', sessionStorage.mobile)
  })

  useEffect(() => {
    // Check for token in sessionStorage
    const token = sessionStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);

      if (token === '1') {
        setUserRole('admin');
        toast.success('Login successful!', { autoClose: 1000 });
      } else if (token === '2') {
        setUserRole('subadmin');
        toast.success('Login successful!', { autoClose: 1000 });
      }
    }
  }, []);
  // const logout = () => {
  //   sessionStorage.removeItem('authToken')
  //   sessionStorage.removeItem('market_name')
  //   sessionStorage.removeItem('mobile')
  //   sessionStorage.removeItem('password')
  //   window.location.reload();
  // }
  let logoutTimer; // Declare a variable to hold the logout timer

  const logout = () => {
    // Remove session items
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('market_name');
    sessionStorage.removeItem('mobile');
    sessionStorage.removeItem('password');
    // Reload the page
    window.location.reload();
    // navigate('/admin');
  };

  const startLogoutTimer = () => {
    // Clear any existing timer
    clearTimeout(logoutTimer);

    // Start a new timer for 10 minutes (600 seconds)
    logoutTimer = setTimeout(() => {
      logout(); // Call logout function after 10 minutes of inactivity
    }, 600000); // 600 seconds * 1000 ms = 600000 milliseconds
  };

  const resetLogoutTimer = () => {
    // Reset the timer on user activity
    startLogoutTimer();
  };

  // Event listeners to reset the timer on user activity
  document.addEventListener('mousemove', resetLogoutTimer); // Mouse move
  document.addEventListener('keypress', resetLogoutTimer); // Key press

  // Start the logout timer when the page loads
  startLogoutTimer();

  return (
    <div className="App">
      <ToastContainer />
      <main>
        <Router>
          {/* If not authenticated, show Login */}
          {!isAuthenticated ? (
            <Login setIsAuthenticated={setIsAuthenticated} setUserRole={setUserRole} />
          ) : (
            <>
              {/* If authenticated, show the main application */}
              {userRole === 'admin' && (<nav className="navbar navbar-dark bg-dark" aria-label="Dark offcanvas navbar">

                <div className="container-fluid">
                  <div className='col-12'>
                    {windowWidth > widthThreshold ? (
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-4'>
                            <div className='row'>
                              <div className='col-6'>
                                <Link to="/admin" className='nav-link navbar-brand col-6'>
                                  <img src={bgmLogo} className='col-md-12 logoimg' /> &nbsp;
                                  BGM GAMES</Link>
                              </div>
                              <div className='col-6 pt-1'> <button className="navbar-toggler col-6" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarDark" aria-controls="offcanvasNavbarDark">
                                <span className="navbar-toggler-icon"></span>
                              </button>
                              </div>
                            </div>
                          </div>
                          <div className='col-8 pt-1'>
                            {/* <label className='float-end userName'> {userData} &nbsp; </label> */}
                            <div className="dropdown menu_user float-end">
                              <a href="#" className="text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon className='' icon="user" /> &nbsp;
                              </a>
                              <ul className="dropdown-menu text-small shadow">
                                <Link to="/admin/changepassword" className='nav-link'><li><a className="dropdown-item" href="">Change Password</a></li></Link>
                                <li><hr className="dropdown-divider" /></li>
                                <li><a className="dropdown-item" onClick={logout}>Log out</a></li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-6' >
                            <div className=''> <Link to="/admin" className='nav-link navbar-brand col-6'>
                              <img src={bgmLogo} className='col-md-12 logoimg' /> &nbsp;
                              BGM GAMES</Link></div>
                          </div>
                          <div className='col-6 pt-1'>
                            <div className='row'>
                              <div className='col-10 flot-end'>
                                <div className="dropdown menu_user window_menu_user float-end">
                                  <a href="#" className="text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon className='' icon="user" />
                                  </a>
                                  <ul className="dropdown-menu text-small shadow">
                                    <Link to="/admin/changepassword" className='nav-link'><li><a className="dropdown-item" href="">Change Password</a></li></Link>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" onClick={logout}>Log out</a></li>
                                  </ul>
                                </div>
                              </div>
                              <div className='col-2 '>
                                <button className="navbar-toggler  float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarDark" aria-controls="offcanvasNavbarDark">
                                  <span className="navbar-toggler-icon"></span>
                                </button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="offcanvas offcanvas-start text-bg-dark" tdata-bs-scroll="true" abindex="-1" id="offcanvasNavbarDark" aria-labelledby="offcanvasNavbarDarkLabel">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title" id="offcanvasNavbarDarkLabel">

                        <Link to="/admin" className='nav-link navbar-brand col-6'>
                          <img src={bgmLogo} className='col-md-12 logoimg' /> &nbsp;
                          BGM GAMES</Link>
                      </h5>
                      <button type="button" className="btn-close btn-close-white m-0" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body" id="scrollbar1">
                      <ul className="navbar-nav justify-content-start flex-grow-1 pe-3">
                        <li className="nav-item">
                          <Link to="/admin" className='nav-link'><FontAwesomeIcon className='fonticon' icon="tachometer-alt" /> Dashboard</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/admin/gussing" className='nav-link'><FontAwesomeIcon className='fonticon' icon="question-circle" /> Gussing</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/admin/video" className='nav-link'><FontAwesomeIcon className='fonticon' icon="video" /> Video</Link>
                        </li>

                        <li className="nav-item">
                          <Link to="/admin/results" className='nav-link'><FontAwesomeIcon className='fonticon' icon="chart-line" /> Results</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/admin/games" className='nav-link'><FontAwesomeIcon className='fonticon' icon="gamepad" /> Games</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/admin/advertisements" className='nav-link'><FontAwesomeIcon className='fonticon' icon="bullhorn" /> Advertisements</Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/admin/vendor" className='nav-link'><FontAwesomeIcon className='fonticon' icon="store" /> Vendor</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>)
              }

              {userRole === 'subadmin' && (
                <nav className="navbar navbar-dark bg-dark" aria-label="Dark offcanvas navbar">
                  <div className="container-fluid">
                    <div className='col-12'>
                      {windowWidth > widthThreshold ? (
                        <div className='col-12'>
                          <div className='row'>
                            <div className='col-3'>
                              <div className='row'>
                                <div className='col-6'>
                                  <Link to="/admin" className='nav-link navbar-brand col-6'>
                                    <img src={bgmLogo} className='col-md-12 logoimg' /> &nbsp;
                                    BGM GAMES</Link></div>
                                <div className='col-6'> <button className="navbar-toggler col-6" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarDark" aria-controls="offcanvasNavbarDark">
                                  <span className="navbar-toggler-icon"></span>
                                </button>
                                </div>
                              </div>
                            </div>
                            <div className='col-9'>
                              <div className="dropdown menu_user float-end">
                                <a href="#" className="text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                  <FontAwesomeIcon className='' icon="user" />
                                </a>
                                <ul className="dropdown-menu text-small shadow">
                                  <Link to="/admin/changepassword" className='nav-link'><li><a className="dropdown-item" href="">Change Password</a></li></Link>
                                  <li><hr className="dropdown-divider" /></li>
                                  <li><a className="dropdown-item" onClick={logout}>Log out</a></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className='col-12'>
                          <div className='row'>
                            {/* <div className='col-6' >
                              <div className=''>   <Link to="/admin" className='nav-link navbar-brand col-6'>
                                <img src={bgmLogo} className='col-md-12 logoimg' /> &nbsp;
                                BGM GAMES</Link></div>
                            </div> */}

                            <div className='col-6'>
                              <Link to="/admin" className='nav-link navbar-brand col-6'>
                                <img src={bgmLogo} className='col-md-12 logoimg' /> &nbsp;
                                BGM GAMES</Link>
                            </div>
                            <div className='col-6'>
                              <div className='row'>
                                <div className='col-10 flot-end'>
                                  <div className="dropdown menu_user window_menu_user float-end">
                                    <a href="#" className="text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                      <FontAwesomeIcon className='' icon="user" />
                                    </a>
                                    <ul className="dropdown-menu text-small shadow">
                                      <li><a className="dropdown-item" href="#">Change Password</a></li>
                                      <li><hr className="dropdown-divider" /></li>
                                      <li><a className="dropdown-item" onClick={logout}>Log out</a></li>
                                    </ul>
                                  </div>
                                </div>
                                <div className='col-2'>
                                  <button className="navbar-toggler  float-end" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarDark" aria-controls="offcanvasNavbarDark">
                                    <span className="navbar-toggler-icon"></span>
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="offcanvas offcanvas-start text-bg-dark" tdata-bs-scroll="true" abindex="-1" id="offcanvasNavbarDark" aria-labelledby="offcanvasNavbarDarkLabel">
                      <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarDarkLabel"></h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                      </div>
                      <div className="offcanvas-body" id="scrollbar1">
                        <ul className="navbar-nav justify-content-start flex-grow-1 pe-3">

                          <li className="nav-item">
                            <Link to="/admin/results" className='nav-link'><FontAwesomeIcon className='fonticon' icon="chart-line" /> Results</Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/admin/games" className='nav-link'><FontAwesomeIcon className='fonticon' icon="gamepad" /> Games</Link>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </nav>
              )}
              <div className='custom-container'>
                <Routes>
                  {userRole === 'admin' && (
                    <>
                      <Route path="/admin" element={<Dashboard gameData={gameData} gussing={gussing} advetise={advetise} video={video} result={result} allVisitor={allVisitor} newVisitor={newVisitor} />} />
                      <Route path="/admin/gussing" element={<Gussing />} />
                      <Route path="/admin/video" element={<Video />} />
                      <Route path="/admin/video/edit" element={<Video />} />
                      <Route path="/admin/video/add" element={<Video />} />
                      <Route path="/admin/results" element={<Results />} />
                      <Route path="/admin/results/edit" element={<Results />} />
                      <Route path="/admin/results/view" element={<Results />} />
                      <Route path="/admin/games" element={<Games />} />
                      <Route path="/admin/games/add" element={<Games />} />
                      <Route path="/admin/games/edit" element={<Games />} />
                      <Route path="/admin/games/addResult" element={<Games />} />
                      <Route path="/admin/advertisements" element={<Advertisements />} />
                      <Route path="/admin/advertisements/add" element={<Advertisements />} />
                      <Route path="/admin/advertisements/edit" element={<Advertisements />} />
                      <Route path="/admin/vendor" element={<Vendor />} />
                      <Route path="/admin/vendor/view" element={<Vendor />} />
                      <Route path="/admin/vendor/edit" element={<Vendor />} />
                      <Route path="/admin/vendor/add" element={<Vendor />} />
                      <Route path="/admin/gussing/add" element={<Gussing />} />
                      <Route path="/admin/gussing/edit" element={<Gussing />} />
                      <Route path="/admin/gussing/view" element={<Gussing />} />
                      {/* <Route path="/admin/visitors" element={<AllVisitorNewVisitor allVisitor={allVisitor}/>} /> */}
                      <Route path="/admin/visitors" element={<AllVisitorNewVisitor allVisitor={allVisitor} newVisitor={newVisitor} />} />
                      {/* <Route path="/admin/changepassword" element={<ChangePassword />} /> */}
                    </>
                  )}
                  {userRole === 'subadmin' && (
                    <>
                      <Route path="/admin/results" element={<Results />} />
                      <Route path="/admin/results/edit" element={<Results />} />
                      <Route path="/admin/results/view" element={<Results />} />
                      <Route path="/admin/games" element={<Games />} />
                      <Route path="/admin/games/add" element={<Games />} />
                      <Route path="/admin/games/edit" element={<Games />} />
                      <Route path="/admin/games/addResult" element={<Games />} />
                    </>
                  )}


                  <Route path="/admin/changepassword" element={<ChangePassword />} />

                </Routes>
              </div>
              <Footer />
            </>
          )}
        </Router>
      </main>
    </div>
  );
};



export default App;
