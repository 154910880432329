import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  //   const handleSubmit = (e) => {
  //   
  //     e.preventDefault();

  //     const mobile = sessionStorage.getItem("mobile");
  //     const password = sessionStorage.getItem("password");

  //     if (!mobile) {
  //       setErrorMessage("Missing authentication details. Please log in again.");
  //       return;
  //     }

  //     if (newPassword !== confirmPassword) {
  //       setErrorMessage("New Password & Confirm Password should be the same.");
  //       return;
  //     } else {
  //       setErrorMessage("");

  //       // Perform the fetch request
  //         fetch("https://api.sattakingvip.co.in/changePassword", {
  //     //   fetch("https://localhost:3000/changePassword", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           mobile,
  //           password,
  //           newPassword,
  //         }),
  //       })
  //         .then((response) => {
  //           if (response.ok) {
  //             return response.json();
  //           } else {
  //             throw new Error("Failed to update password");
  //           }
  //         })
  //         .then((data) => {
  //           console.log("Password change successful:", data.message);

  //           // Log session items before clearing
  //           console.log("Auth token:", sessionStorage.getItem("authToken"));
  //           console.log("Market name:", sessionStorage.getItem("market_name"));
  //           console.log("Mobile:", sessionStorage.getItem("mobile"));
  //           console.log("Password:", sessionStorage.getItem("password"));

  //           // Clear session storage
  //           sessionStorage.removeItem("authToken");
  //           sessionStorage.removeItem("market_name");
  //           sessionStorage.removeItem("mobile");
  //           sessionStorage.removeItem("password");

  //           // Force reload the page
  //           window.location.reload(true);
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error.message);
  //           setErrorMessage("Password update failed. Please try again.");
  //         });
  //     }
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    let mobile = sessionStorage.getItem("mobile");
    let password = sessionStorage.getItem("password");
    if (newPassword !== confirmPassword) {
      setErrorMessage("New Password & Confirm Password Should be same");
    } else {
      setErrorMessage("");
      // Proceed with form submission logic (e.g., send to server)
      fetch("https://api.sattakingvip.co.in/changePassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile,
          password,
          newPassword,
        }),
      }).catch((error) => console.error(error));

      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("market_name");
      sessionStorage.removeItem("mobile");
      sessionStorage.removeItem("password");
      window.location.reload();
    }
  };
  return (
    <div className="p-0 vh-100 changePassMain">
      <div className="col-12 changePassword text-white">
        <div className="card">
          <div className="card-header text-center p-3">
            <h3 className="text-center">Change Password</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="col-12">
                <div className="row">
                  <div className="mb-3 mt-3">
                    <label htmlFor="newPassword" className="form-label">
                      New Password
                    </label>
                    <div className="input-group">
                      <input
                        type={newPasswordVisible ? "text" : "password"}
                        className="form-control"
                        id="newPassword"
                        value={newPassword}
                        required
                        onChange={handleNewPasswordChange}
                      />
                      <button
                        type="button"
                        className="btn bg-white"
                        onClick={() =>
                          setNewPasswordVisible(!newPasswordVisible)
                        }
                      >
                        {newPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm Password
                    </label>
                    <div className="input-group">
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="form-control"
                        id="confirmPassword"
                        value={confirmPassword}
                        required
                        onChange={handleConfirmPasswordChange}
                      />
                      <button
                        type="button"
                        className="btn bg-white"
                        onClick={() =>
                          setConfirmPasswordVisible(!confirmPasswordVisible)
                        }
                      >
                        {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </div>
                  {errorMessage && (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  )}
                  <div className="mb-3 mt-3 card-footer">
                    <button
                      type="submit"
                      className="col-md-12 col-sm-12 btn btn-primary"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
