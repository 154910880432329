import { useEffect, useState } from "react"
import { json } from "react-router-dom";
import App from "../App";
import getVisitorStats from "./utilities/getVisitorData";
import moment from "moment";
import LoadingSpiner from "./utilities/loadingSpinner";
const momenttz = require('moment-timezone')

function GetAllDetails() {
    const baseUrl = 'https://api.sattakingvip.co.in/'
    const endpoints = ['gameData', 'updateguessing', 'advertisementUpdate', 'videoUpdate', 'resultUpdate'];
    const [getGamesData, setGamesData] = useState([]);
    const [getGussingsData, setGussingsData] = useState([]);
    const [getAdvertisment, setAdvertisment] = useState([]);
    const [getVideos, setVideos] = useState([]);
    const [getResults, setResults] = useState([]);

    const [loading, setLoading] = useState(true)

    const [getAllVisitor, setAllVisitor] = useState([]);
    const [getnewVisitor, setnewVisitor] = useState([]);
    var currentDate = moment().tz('Asia/Kolkata').format("YYYY-MM-DD");

    useEffect(() => {
        Promise.all(endpoints.map(endpoint => fetchData(endpoint)))
            .then(([gamesData, guessData, adData, videoData, resultData]) => {

                if (setGamesData(gamesData.length) ||
                    setGussingsData(guessData.length) ||
                    setAdvertisment(adData.length) ||
                    setVideos(videoData.length) ||
                    setResults(resultData.length)) {

                    setLoading(true)
                } else {
                    setLoading(false)

                }


            })
            .catch(error => {

                console.error('Error fetching data:', error);
                setLoading(false);

            });
    }, []);


    const fetchData = async (endpoint) => {
        const response = await fetch(`${baseUrl}${endpoint}`, {
            method: 'POST', // or 'PUT' depending on your requirements
            headers: {
                'Content-Type': 'application/json', // specify the content type
            },
            body: JSON.stringify(),
        });
        return response.json();
    };


    useEffect(() => {
        const siteName = window.location.hostname; // Use the domain name as the site name
        const currentDate = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

        getVisitorStats()
            .then(visitorData => {
                setAllVisitor(visitorData);
                const newVisitors = [];
                const allVisitors = [];

                for (let index = 0; index < visitorData?.newVisitors?.length; index++) {
                    const element = visitorData.newVisitors[index];
                    const visitDate = moment(element.visit_date).format("YYYY-MM-DD");

                    if (visitDate === currentDate) {
                        newVisitors.push(element);
                        // console.log('New visitor:', element);
                    } else {
                    }
                    allVisitors.push(element);
                    // console.log('All visitor:', element);
                }

                setnewVisitor(newVisitors);
                setAllVisitor(allVisitors);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching visitor stats:', error);
                setLoading(false)
            });
    }, []);

    return (
        <>
            {loading ? (<LoadingSpiner />) : (
                <App gameData={getGamesData} gussing={getGussingsData} advetise={getAdvertisment} video={getVideos} result={getResults} allVisitor={getAllVisitor} newVisitor={getnewVisitor} />
            )

            }
            {/* <h1>Visitor Statistics</h1>
            <p>Total Visitors: {getAllVisitor?.length}</p>
            <p>New Visitors Today: {newVisitor}</p> */}
        </>

    )
}
export default GetAllDetails